<template>


  <div class="about w-11/12 lg:w-7/12 mx-auto pt-6" id="main">
    <h1 class="text-3xl lg:text-4xl uppercase font-bold text-left">ОБРАЗОВАНИЕ</h1>
    <div class="description w-4/5  lg:w-2/3 my-4 ml-4">
      <p class="text-left text-xl">
        Наша компания оказывает услуги в области дополнительного профессионального образования (ДПО), включая повышение
        квалификации. Мы представляем широкий спектр возможностей для обучения.
      </p>
    </div>

    <div class="flex mt-6 plus">
      <div class="flex flex-col md:flex-row md:max-w-xl w-11/12 mx-auto lg:mx-0 lg:w-9/12 rounded-lg bg-white shadow-xl">
        <img
            class=" w-full h-100 md:h-auto object-cover md:w-100 shadow-xl rounded-t-lg md:rounded-none md:rounded-l-lg"
            src="/images/backgrounds/1.jpg" style="  min-height: 270px;
             background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;" alt=""/>
        <div class="p-6 flex flex-col justify-center shadow-xl rounded-r-lg">
          <h5 class="text-gray-900 text-xl text-left ml-2 font-semibold mb-2">Преподаватели</h5>
          <p class="text-gray-700 text-left text-sm lg:text-lg mb-4 lg:w-300" >
            Наш преподавательский состав - это квалифицированные специалисты, обладающие теоретическими и практическими
            знаниями.
          </p>
        </div>
      </div>
    </div>
    <div class="flex plus mt-4">
      <div class="flex flex-col md:flex-row md:max-w-xl w-11/12 mx-auto lg:mx-0 lg:w-9/12 rounded-lg bg-white shadow-xl">
        <img
            class=" w-full h-100 md:h-auto object-cover md:w-100 shadow-xl rounded-t-lg md:rounded-none md:rounded-l-lg"
            src="/images/backgrounds/2.jpg" style="  min-height: 270px;
             background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;" alt=""/>
        <div class="p-6 flex flex-col justify-center shadow-xl rounded-r-lg">
          <h5 class="text-gray-900 text-xl text-left ml-2 font-semibold mb-2">Направления</h5>
          <p class="text-gray-700 text-left text-sm lg:text-lg mb-4 lg:w-300">
            Образовательные программы курсов разрабатываются нами с учетом передового опыта в сфере дополнительного
            профессионального образования.
          </p>
        </div>
      </div>
    </div>


    <div class="description w-11/12 lg:w-2/3 my-4 mt-10 lg:mt-20" id="programs">
      <p class="text-left text-3xl lg:text-4xl font-semibold">
        Более 50 программ различного профиля и направленности
      </p>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-3">
      <div v-for="program,i in getPrograms" :key="i">
        <button type="button"
                @click="routeProgram(i)"
                class="w-full h-full text-left lg:text-center inline-block px-6 font-bold text-lg py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out">
          {{ program.name }}
        </button>

      </div>
    </div>
  </div>
  <div class="reviews w-11/12 lg:w-7/12 mx-auto pt-6 lg:mt-20">
    <h1 class="text-3xl lg:text-4xl uppercase font-bold text-left">Благодарности</h1>

    <div class="description w-4/5 lg:w-2/3 my-4 ml-4">
      <p class="text-left text-xl">
        Успех любого бизнеса - это стабильная работа, профессионализм сотрудников, отсутствие рисков.
      </p>

      <p class="text-left text-xl">
        Мы помогаем Вам быть компетентными и высокопрофессиональными.
      </p>

      <p class="text-left text-xl">
        Мы поддержим Вас в стремлении быть лучшей компанией и лучшим специалистом.
      </p>

    </div>
    <div class="flex flex-col lg:flex-row justify-around mt-4">
      <img v-for="review,i in reviews.imgs" class="cursor-pointer rounded-md" style="height:300px;"
           @click="showReview(i)" :src="review" :key="i">
    </div>

  </div>
  <VueEasyLightbox
      :visible="reviews.visible"
      :imgs="reviews.imgs"
      :index="reviews.index"
      @hide="handleHide"
  ></VueEasyLightbox>
</template>
<style>
.about .plus.flex:hover {
  margin-left: 5px;
}

.about .flex {
  transition: 150ms;
}

a.footer {
  color: #83ffc6 !important;
  transition: 0.1s;
}

a.footer:hover {
  color: #46f6a5 !important;
}
</style>
<script>
// @ is an alias to /src
import VueEasyLightbox from 'vue-easy-lightbox'


import gsap from 'gsap'

export default {
  name: 'Home',
  data() {
    return {
      number: 0,
      modalProgram: false,
      tweened: 0,
      reviews: {
        imgs: ['/images/reviews/1.webp', '/images/reviews/2.webp', '/images/reviews/3.webp',],  // Img Url , string or Array
        visible: false,
        index: 0   // default
      },
    }
  },
  components: {
    VueEasyLightbox
  },
  watch: {
    number(n) {
      gsap.to(this, {duration: 0.5, tweened: Number(n) || 0})
    }
  },
  methods: {
    routeProgram(i) {
      console.log(i)
      this.$store.commit('currentProgram', i)
      this.$router.push({name: 'Program', params: {programID: i}})
      setTimeout(function () {
        document.getElementById('program').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 10)
    },
    showReview(id) {
      this.reviews.index = id  // index of imgList
      this.show()
    },
    show() {
      this.reviews.visible = true
    },
    handleHide() {
      this.reviews.visible = false
    }
  },
  computed: {
    getPhone() {
      console.log(this.$store.state.phone);
      return this.$store.state.phone
    },
    getReservePhone() {
      return this.$store.state.reservePhone
    },
    getPrograms() {
      return this.$store.state.programs
    },
  },
  mounted() {
    this.number = 55
      if(this.$store.state.mainSwipe) document.getElementById('main').scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      else this.$store.commit('mainSwipe', 1)
  }
}
</script>
